import React, {Fragment} from 'react'
import './HeadLine.css'
const HeadLine = () => {
  return (
    <Fragment>
        <h1 className='HeadLine_h1'>This training is 100% FREE and reveals the secrets about...</h1>
    </Fragment>
  )
}

export default HeadLine